.bookings-list__row {
  display: grid;
  grid-template-areas:
    "bookingSchedule bookingSchedule"
    "customerName customerName"
    "bookingPayments bookingActions";
  grid-template-columns: auto auto;
  align-items: center;
  @apply gap-0;
  @apply py-2;
}

@screen md {
  .bookings-list__row {
    grid-template-areas: "bookingSchedule customerName bookingPayments bookingActions";
    grid-template-columns: 12rem auto 8rem 9rem;
    @apply gap-0;
    @apply py-2;
  }
}

@screen lg {
  .bookings-list__row {
    grid-template-areas: "bookingStatus bookingSchedule customerName bookingPayments bookingActions";
    grid-template-columns: 7rem 12rem auto 8rem 9rem;
  }
}
