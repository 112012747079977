.ag-sort-none-icon {
  opacity: 0.5;
}

.ag-cell-wrapper {
  height: 100%;
}

.ag-cell-value {
  height: 100%;
  overflow: hidden;
}
