.calendar-cell--range .day-range-start[aria-selected] {
  @apply rounded-l-md;
}

.calendar-cell--range .day-range-end[aria-selected] {
  @apply rounded-r-md;
}

.calendar-cell--single > [aria-selected] {
  @apply rounded-md;
}
