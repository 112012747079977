.booking-details__grid {
  grid-template-areas:
    "details"
    "schedule"
    "items"
    "documents"
    "payments";
  grid-template-columns: 100%;
}

.bookingItem__gridCols {
  grid-template-columns: 100%
  @apply gap-1;
}


@screen md {
  .booking-details__grid {
    grid-template-areas:
      "details schedule"
      "items items"
      "documents documents"
      "payments payments";
    grid-template-columns: minmax(300px, 1fr) minmax(400px, 1fr);
  }

  .bookingItem__gridCols {
    grid-template-columns:
      minmax(150px, 1fr)
      minmax(150px, 1fr)
      minmax(130px, 1fr)
      minmax(80px, 1fr)
      minmax(80px, 1fr)
      minmax(80px, 1fr);
    gap: 0.1rem;
  }  
}

@media (min-width: 1280px) {
  .booking-details__grid {
    grid-template-areas:
      "details schedule documents"
      "items items payments";
    grid-template-columns: minmax(300px, 3fr) minmax(400px, 3fr) minmax(240px, 2fr);
  }
}
