@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --accent-8: #e9f2f6;
    --accent-9: #0b484e;
    --accent-10: #073c41;
    --highlight: #ffd894;

    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: #0b484e;
    --primary-foreground: white;

    --secondary: 242 239 234;
    --secondary-light: 248 246 244;
    --secondary-lighter: 251 249 247;
    --secondary-dark: 237 232 225;
    --secondary-darker: 219 215 206;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --warn: rgb(245, 158, 11);
    --warn-foreground: rgb(245, 158, 11);

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.grid-template-columns-1 { grid-template-columns: 1fr; }
.grid-template-columns-2 { grid-template-columns: 1fr 1fr; }
.grid-template-columns-3 { grid-template-columns: 1fr 1fr 1fr; }
.grid-template-columns-4 { grid-template-columns: 1fr 1fr 1fr 1fr; }
.grid-template-columns-6 { grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; }
.grid-template-columns-12 { grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; }

input.no-number-controls::-webkit-outer-spin-button,
input.no-number-controls::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.no-number-controls[type=number] {
    -moz-appearance: textfield;
}

a.external-link::after {
  content: '';
  padding: 0.5rem 0.6rem;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWV4dGVybmFsLWxpbmsiPjxwYXRoIGQ9Ik0xNSAzaDZ2NiIvPjxwYXRoIGQ9Ik0xMCAxNCAyMSAzIi8+PHBhdGggZD0iTTE4IDEzdjZhMiAyIDAgMCAxLTIgMkg1YTIgMiAwIDAgMS0yLTJWOGEyIDIgMCAwIDEgMi0yaDYiLz48L3N2Zz4=') 100% 50% no-repeat transparent;
  background-size: 1em 1em;
}

input.placeholder-opacity-20::-webkit-input-placeholder,
input.placeholder-opacity-20::placeholder {
  opacity: 0.2;
}


.dragging {
  transform:translateX(-9999px);
}
